import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { FaCopy } from 'react-icons/fa';
import './App.css';

function App() {
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [isListed, setIsListed] = useState(false);
  const [containerData, setContainerData] = useState([]);
  const [timer, setTimer] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  const web3 = new Web3(window.ethereum);
  const contractAddress = '0x17566Db04C64838f94E5d1Fa0E15ce508ACbE19A';
  const contractAddressGame = '0x28C455b5B0Ee0575AC4f2d14167ac38D01BFcFd0';
  const contractABI = [{"inputs":[{"internalType":"address","name":"IERC20_Token","type":"address"}],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[],"name":"beInvestor","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"feeInvestor","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"_address","type":"address"}],"name":"isAddressListed","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"owner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
  const contractABIGame = [{"inputs":[{"internalType":"address","name":"IERC20_Token","type":"address"},{"internalType":"address","name":"Token_Investor","type":"address"}],"stateMutability":"nonpayable","type":"constructor"},{"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"submission","type":"address"},{"indexed":true,"internalType":"uint256","name":"fee","type":"uint256"}],"name":"SUBMISSION","type":"event"},{"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"submission","type":"address"},{"indexed":true,"internalType":"uint256","name":"fee","type":"uint256"}],"name":"WINNER","type":"event"},{"inputs":[{"internalType":"uint256","name":"_minBalance","type":"uint256"}],"name":"MinBalance","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"Participate","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"Win","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[{"internalType":"uint256","name":"_a","type":"uint256"},{"internalType":"uint256","name":"_b","type":"uint256"}],"name":"changeTime","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"feeInv","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"feeParticipate","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"owner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
  const chainId = 534352;
  const contract = new web3.eth.Contract(contractABI, contractAddress, {
    chainId: chainId
  });
  const contractGame = new web3.eth.Contract(contractABIGame, contractAddressGame, {
    chainId: chainId
  });

  const callbeInvestor = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const gas = 143269;
      const gasPrice = await web3.eth.getGasPrice();
      await contract.methods.beInvestor().send({ from: accounts[0], "value": 100000000000000, gas, gasPrice });
      alert("Function executed successfully.");
    } catch (error) {
      alert.error("Failed to execute function:", error);
    }
  };

  const callParticipate = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const gas = 152020;
      const gasPrice = await web3.eth.getGasPrice();
      if(isListed){
        await contractGame.methods.Participate().send({ from: accounts[0], "value": 1000000000000, gas, gasPrice });
      }
      else{
        await contractGame.methods.Participate().send({ from: accounts[0], "value": 10000000000000, gas, gasPrice });  
      }
      alert("Function executed successfully.");
    } catch (error) {
      alert.error("Failed to execute function:", error);
    }
  };

  const callWin = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const gas = 143269;
      const gasPrice = await web3.eth.getGasPrice();
      await contractGame.methods.Win().send({ from: accounts[0], gas, gasPrice });  
      alert("Function executed successfully.");
      setTimer(3600); // Set timer to 60 minutes
      setTimerRunning(false); // Stop the timer
    } catch (error) {
      alert.error("Failed to execute function:", error);
    }
  };

  let lastWinnerBlockNumber = 0;
  const fetchData = async () => {
    if (!connected) {
      setIsListed(false);
      setContainerData([]);
      return;
    }
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const isAddressListed = await contract.methods.isAddressListed(accounts[0]).call({ from: accounts[0] });
      setIsListed(isAddressListed);
  
      // Winner eventlerini dinleyin ve her birini aldığınızda lastWinnerBlockNumber'ı güncelleyin
    contractGame.events.WINNER({ fromBlock: 0 }, (error, event) => {
      if (!error) {
        console.log("Winner event received:", event);
        lastWinnerBlockNumber = event.blockNumber;
        fetchSubmissions(lastWinnerBlockNumber);
      } else {
        console.error("Error listening to Winner event:", error);
      }
    });
    } catch (error) {
      console.error("Failed to fetch isAddressListed value:", error);
      setIsListed(false);
      setContainerData([]);
    }
  };
  
  const fetchPastEvents = async () => {
    try {
      const pastEvents = await contractGame.getPastEvents('SUBMISSION', {
        fromBlock: '0x0',
        toBlock: 'latest',
      });
  
      // Geçmiş eventleri containerData state'ine ekleyin
      const formattedEvents = pastEvents.map(event => ({
        address: event.returnValues.submission,
        fee: event.returnValues.fee,
      }));
      setContainerData(prevData => [...prevData, ...formattedEvents]);
    } catch (error) {
      console.error("Failed to fetch past events:", error);
    }
  };
  
  
  const fetchSubmissions = async (startBlock) => {
    try {
      const pastEvents = await contractGame.getPastEvents('SUBMISSION', {
        fromBlock: startBlock + 1,
        toBlock: 'latest',
      });
  
      const formattedEvents = pastEvents.map(event => ({
        address: event.returnValues.submission,
        fee: event.returnValues.fee,
      }));
      setContainerData(prevData => [...prevData, ...formattedEvents]);
    } catch (error) {
      console.error("Failed to fetch past events:", error);
    }
  };

  const connectWallet = async () => {
  if (window.ethereum && !connected) {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    if (accounts.length > 0) {
      setConnected(true);
      setAddress(accounts[0]);
      checkChainId();
      fetchData(); // fetchData burada çağrılıyor
    } else {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setConnected(true);
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setAddress(accounts[0]);
        checkChainId();
        fetchData(); // fetchData burada çağrılıyor
      } catch (error) {
        console.error("User denied account access.");
      }
    }
  } else {
    console.error("MetaMask not found.");
  }
  web3.eth.getChainId().then(console.log);
};

    const checkChainId = async () => {
      try {
        const controlchainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (parseInt(controlchainId, 16) !== chainId) {
          const result = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }]
          });
          if (result) {
            // Eğer kullanıcı ağı değiştirdiyse, sayfayı yenile
            window.location.reload();
          }
        }
      } catch (error) {
        if (error.code === 4902) {
          // Eğer chain MetaMask'e eklenmemişse, ekleme işlemi yap
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: `0x${chainId.toString(16)}`,
              chainName: 'Scroll Network', // Ağ adı
              nativeCurrency: {
                name: 'ETH', // Token adı
                symbol: 'ETH', // Token sembolü
                decimals: 18, // Token ondalık basamak sayısı
              },
              rpcUrls: ['https://rpc.scroll.io/'], // RPC URL'si
              blockExplorerUrls: ['https://scrollscan.com/'] // Block Explorer URL'si
            }]
          });
        }
      }
    };

    const addTokenToMetamask = async () => {
      const tokenAddress = '0x57C788EC9b488c059CcaD77992cBc424891BeefA';
      const tokenSymbol = 'GAMEME';
      const tokenDecimals = 18;
  
      try {
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });
  
        if (wasAdded) {
          console.log('Token added to MetaMask!');
        } else {
          console.log('Failed to add token to MetaMask.');
        }
      } catch (error) {
        console.error('Error adding token to MetaMask:', error);
      }
    };
  
    useEffect(() => {
      fetchData(); // Şu anki eventleri dinle
      fetchPastEvents(); // Geçmiş eventleri al
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected]); // Bağlantı durumu değiştiğinde çalışsın
    
    useEffect(() => {
      if (connected) {
      setIsListed(true); // varsayılan olarak true yapabilirsiniz
      }
    }, [connected]);

    useEffect(() => {
      let interval;
      if (timerRunning && timer > 0) {
        interval = setInterval(() => {
          setTimer(prevTimer => prevTimer - 1);
        }, 1000);
      } else if (timer === 0) {
        // Timer has reached 0, do something here
        // For example, stop the timer and reset it
        setTimerRunning(false);
        setTimer(3600); // Reset timer to 60 minutes
      }
      
      return () => clearInterval(interval);
    }, [timer, timerRunning]);

  const handleDisconnect = () => {
    setConnected(false);
    setAddress('');
  };
  
  const copyAddress = () => {
    navigator.clipboard.writeText(address);
    alert("Address copied to clipboard!");
  };

  const shortenAddress = (addr) => {
    return addr.substring(0, 4) + '...' + addr.substring(addr.length - 4);
  };

  return (
    <div className="App">
      <header>
        <div className="navbar">
        <div className="navbar-left">
        <button onClick={() => window.location.href = 'Whitepaper.pdf'}>Gameme Whitepaper</button>
        </div>
          <div className="navbar-center">
            <button className="timer">{Math.floor(timer / 60).toString().padStart(2, '0')}:{(timer % 60).toString().padStart(2, '0')}</button>
            <button className="participate" onClick={callParticipate}>Participate</button>
            <button className="selectwinner" onClick={callWin}>Win</button>
            <button className="investor" onClick={callbeInvestor}>Claim</button>
          </div>
          <div className="navbar-right">
            {connected ? (
            <div className="connected-info">
            <span style={{ color: connected && isListed ? 'green' : 'white' }}>{shortenAddress(address)}</span>
            <button onClick={copyAddress}><FaCopy /></button>
          </div>
          ) : null}
          {connected ? (
          <button onClick={handleDisconnect}>Disconnect</button>
          ) : (
          <button id="connectButton" onClick={connectWallet}>Connect</button>
          )}
        </div>
        </div>
      </header>
      <main>
      <div className="container">
        {containerData.map((data, index) => (
          <div key={index} className="box">
          <p>Participate {index + 1}</p>
          <p>{shortenAddress(data.address)}<button onClick={() => copyAddress("data.address")}><FaCopy /></button></p>
          <p>{data.amount}</p>
          </div>
        ))}
      </div>
      </main>
      <footer>
        <button onClick={addTokenToMetamask}>Add Gameme Token to MetaMask</button>
        <button onClick={() => window.location.href = 'https://twitter.com/Gameme_Scroll'}>Twitter(X)</button>
      </footer>
    </div>
  );
}

export default App;